import React from "react"
import { Box, Heading, Paragraph } from "grommet"

import Layout from "../components/layout"
import Seo from "../components/seo"

const PrivacyPolicyPage = () => (
  <Layout>
    <Seo title="Privacy policy" />
    <Box align="center" pad="large">
      <Heading alignSelf="center">Условия за поверителност</Heading>
      <Paragraph>В процес на изготвяне</Paragraph>
    </Box>
  </Layout>
)

export default PrivacyPolicyPage
